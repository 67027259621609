import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import ProductImages from "../components/Product/ProductImages"
import ProductInfo from "../components/Product/ProductInfo"

const ProductPage = ({ data: { product, suggestions }, location }) => {
  const {
    title,
    description,
    images,
    images: [firstImage],
  } = product

  const path = location.pathname

  const [displayImages, setDisplayImages] = React.useState(images)

  const hasImages = displayImages.length > 0

  return (
    <Layout store={true}>
      <Seo 
        title={title}
        description={description}
        fullTitle={false}
        path={path}
        image={hasImages ? displayImages[0] : null}
      />
      <ProductInfo 
        product={product} 
        mainImage={hasImages ? displayImages[0] : null} 
        setDisplayImages={setDisplayImages}
      />
      <ProductImages images={displayImages} title={title} />
    </Layout>
  )
}

export default ProductPage

export const productPageQuery = graphql`
  query($slug: String!) {
    product: shopifyProduct(handle: { eq: $slug }) {
      title
      description
      productType
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      images {
        altText
        id
        shopifyId
        gatsbyImageData
        originalSrc
      }
      variants {
        availableForSale
        storefrontId
        shopifyId
        title
        price
        selectedOptions {
          name
          value
        }
        image {
          altText
          id
          gatsbyImageData
          originalSrc
        }
      }
      options {
        name
        values
        id
      }
      metafields {
        value
        key
      }
    }
  }
`
