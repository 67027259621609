import * as React from "react"
import isEqual from "lodash.isequal"
import { StoreContext } from "../../context/store-context"
import { AddToCart } from "../Cart/AddToCart"
import { formatPrice } from "../../utils/format-price"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import variables from "../../styles/variables"
import breakpoints from "../../styles/breakpoints"
import Chevron from "../../../static/chevron.svg"

const ProductInfoWrapper = styled.div`
  width: 34%;
  padding-left: ${variables.pagePaddingDesktop};
  padding-top: 22px;

  @media (max-width: 1300px) {
    width: 40%;
  }
  
  @media (max-width: 1100px) {
    width: 46%;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    padding-right: ${variables.pagePaddingMobile};
    padding-left: ${variables.pagePaddingMobile};
  }
`

const ProductInfoImageWrapper = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`

const Header = styled.h1`
  padding-bottom: 32px;
  font-size: ${variables.textL};
  line-height: ${variables.lineHeightL};

  @media (min-width: ${breakpoints.md}) {
    font-size: ${variables.textXL};
    line-height: ${variables.lineHeightXL};
  }
`

const PriceValue = styled.h2`
  padding-bottom: 20px;

  span {
    font-size: ${variables.textSm};
    line-height: ${variables.lineHeightSm};
    font-family: 'Century Schoolbook', serif;

    @media (min-width: ${breakpoints.md}) {
      font-size: ${variables.textS};
      line-height: ${variables.lineHeightS};
    }
  }
`

const EmphasizedText = styled.p`
  font-size: ${variables.textSm};
  line-height: ${variables.lineHeightSm};
  font-family: 'Century Schoolbook', serif;
  font-style: italic;
  padding-bottom: 20px;

  @media (min-width: ${breakpoints.md}) {
    font-size: ${variables.textS};
    line-height: ${variables.lineHeightS};
  }
`

const LabelFont = styled.span`
  font-size: ${variables.textSm};
  line-height: ${variables.lineHeightSm};
  display: block;

  @media (min-width: ${breakpoints.md}) {
    font-size: ${variables.textS};
    line-height: ${variables.lineHeightS};
  }
`

const MetaSection = styled.div`
  padding-bottom: 38px;
`

const ProductDescription = styled.p`
  font-size: ${variables.textSm};
  line-height: ${variables.lineHeightSm};
  font-family: 'Century Schoolbook', serif;
  padding-top: 20px;
  padding-bottom: 28px;
  border-top-width: 2px;
  border-bottom-width: 1px;

  @media (min-width: ${breakpoints.md}) {
    font-size: ${variables.textS};
    line-height: ${variables.lineHeightS};
  }
`

const SelectLabel = styled.label`
  font-size: ${variables.textS};
  line-height: ${variables.lineHeightS};
`

const OptionsFieldset = styled.fieldset`
  padding-top: 12px;
  border-top-width: 1px;
  border-color: inherit;
`

const SelectVariant = styled.div`
  border: none;
  cursor: pointer;
  min-width: 24ch;
  position: relative;

  select {
    appearance: none;
    background-color: transparent;
    border: none;
    color: ${variables.colorBlackFG};
    cursor: inherit;
    font-size: ${variables.textS};
    font-weight: ${variables.fontWeightM};
    height: 44px;
    margin: 0;
    padding: 4px 56px 4px 16px;
    width: 100%;
  }

  ::after {
    background-image: url(${Chevron});
    background-repeat: no-repeat;
    content: "";
    height: 16px;
    position: absolute;
    right: ${variables.spaceLG};
    transform: translateY(100%) translateX(20%);
    width: 42px;
    pointer-events: none;
  }
`

const ProductInfo = ({product, mainImage, setDisplayImages}) => {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    description,
    metafields,
    images
  } = product

  const { client } = React.useContext(StoreContext)

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const getVariantImageIds = () => {
    let idSet = new Set([])
    for (const variant of variants) {
      if (variant.image) {
        idSet.add(variant.image.id)
      }
    }
    return Array.from(idSet)
  }

  const getVariantDimensionsImage = (variant) => {
    const index = variants.findIndex(item => {
      return item.shopifyId === variant.shopifyId
    })
    return images[index + 1]
  }

  const setDisplayedImages = (variant) => {
    let displayedImages = []
    const variantImage = getVariantDimensionsImage(variant)
    for (const image of images) {
      displayedImages.push(image)
    }
    if (displayedImages.length > 0) {
      displayedImages.splice(1, variants.length, variantImage)
      setDisplayImages(displayedImages)
    }
  }

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.storefrontId
          ) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const handleOptionChange = (index, event) => {
    const value = event.target.value

    if (value === "") {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })

    setDisplayedImages(selectedVariant)
  }

  const handleVariantChange = (event) => {
    const variantIndex = parseInt(event.target.value)

    const variant = variants[variantIndex]

    setVariant({ ...variant })

    setDisplayedImages(variant)
  }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const hasVariants = variants.length > 1

  React.useEffect(() => {
    if (hasVariants) {
      setDisplayedImages(initialVariant)
    }
  }, [])

  const getProductData = () => {
    if (metafields) {
      for (const metafield of metafields) {
        if (metafield.key === "product_data") {
          const productData = metafield.value.replace(/\[|\"|\]/g, "").split(",")
          return productData
        }
      }
    }
    return []
  }

  const getOptionsPermutationNumber = () => {
    let number = 1
    options.forEach((option) => {
      number = number * option.values.length
    })
    return number
  }

  const optionPermutations = getOptionsPermutationNumber()

  const productData = getProductData()

  return (
    <React.Fragment>
      {mainImage && <ProductInfoImageWrapper>
        <GatsbyImage image={mainImage.gatsbyImageData} alt={mainImage.altText || `Product Image of ${title} #1`} />
      </ProductInfoImageWrapper>}
      <ProductInfoWrapper>
        <Header>{title}</Header>
        <PriceValue>
          <span>{price}</span>
        </PriceValue>
        <EmphasizedText>Tax included.</EmphasizedText>
        <MetaSection>
          {productData.map((item, index) => {
            return <LabelFont key={index}>{item}</LabelFont>
          })}
        </MetaSection>
        {optionPermutations === variants.length ? 
        <OptionsFieldset>
          {hasVariants &&
            options.map(({ id, name, values }, index) => (
              name === "Frame" && <SelectVariant key={id}>
                <SelectLabel>{name}</SelectLabel>
                <select
                  aria-label="Variants"
                  onChange={(event) => handleOptionChange(index, event)}
                >
                  {values.map((value) => (
                    <option value={value} key={`${name}-${value}`}>
                      {value}
                    </option>
                  ))}
                </select>
              </SelectVariant>
            ))}
        </OptionsFieldset>
        :
        <OptionsFieldset>
          {hasVariants && 
            <SelectVariant>
              <SelectLabel>Frame</SelectLabel>
              <select
                aria-label="Variants"
                onChange={(event) => handleVariantChange(event)}
              >
                {variants.map((variant, index) => (
                  <option value={index} key={`Label-${variant.title}`}>
                    {variant.title.split(" / ").pop()}
                  </option>
                ))}
              </select>
            </SelectVariant>
          }
        </OptionsFieldset>
        }
        <div>
          <AddToCart
            variant={productVariant}
            quantity={quantity}
            available={available}
            product={product}
          />
          <AddToCart
            variant={productVariant}
            quantity={quantity}
            available={available}
            buyNow={true}
            product={product}
          />
        </div>
        <ProductDescription>{description}</ProductDescription>
      </ProductInfoWrapper>
    </React.Fragment>
  )
}

export default ProductInfo
