import * as React from "react"
import { StoreContext } from "../../context/store-context"
import ArrowDark from "../../assets/svg/arrow-dark.svg"
import ArrowLight from "../../assets/svg/arrow-light.svg"
import variables from "../../styles/variables"
import styled from "styled-components"

const AddToCartButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${props => props.buyNow ? variables.colorWhiteFG : variables.colorBlackFG};
  background-color: ${props => props.buyNow ? variables.colorBlackFG : variables.colorWhiteBG};
  border-color: ${variables.colorBlackFG};
  width: 100%;
  padding: 22px 19px;
  border-width: 1pt;
  margin-top: 15px;
  margin-bottom: 15px;

  svg {
    width: 32px;
  }
`

export function AddToCart({ buyNow = false, variant, quantity, available, product, ...props }) {
  const { addVariantToCart, checkout, loading } = React.useContext(StoreContext)

  const variantId = variant.storefrontId
  const {price} = variant

  const productImage = product.images.length > 0 ? product.images[0] : undefined

  const variantShopifyId = variant.shopifyId.match(/\d+/)[0]

  function addToCart(e) {
    e.preventDefault()
    addVariantToCart(
      variantId,
      quantity,
      product,
      price,
      productImage,
      variantShopifyId
    )
    if (buyNow) {
      setTimeout(() => handleCheckout(), 3000)
    }
  }

  const handleCheckout = () => {
    window.location.href = checkout.webUrl
  }

  return (
    <AddToCartButton
      type="submit"
      buyNow={buyNow}
      onClick={addToCart}
      disabled={!available || loading}
      {...props}
    >
      {available ? buyNow ? "Buy now" : "Add to Cart" : "Out of Stock"}
      {available ? buyNow ? <ArrowLight /> : <ArrowDark /> : null}
    </AddToCartButton>
  )
}
