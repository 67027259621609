import * as React from "react"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import styled from "styled-components"
import variables from "../../styles/variables"

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100%;

  img {
    width: 100%;
  }
`

const ProductImageWrapper = styled.div`
  width: 66%;
  padding-top: 22px;
  padding-right: ${variables.pagePaddingDesktop};
  padding-left: 19px;
  padding-bottom: 480px;

  .productImage:first-of-type {
    display: auto !important;
  }

  @media (max-width: 1300px) {
    width: 60%;
  }
  
  @media (max-width: 1100px) {
    width: 54%;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    padding-left: ${variables.pagePaddingMobile};
    padding-bottom: 100px;

    .productImage:first-of-type {
      display: none !important;
    }
  }
`

const ProductImages = ({images, title}) => {
  return (
    <ProductImageWrapper>
      <div
        role="group"
        aria-label="gallery"
        aria-describedby="instructions"
      >
        {images.map((image, index) => (
          <StyledGatsbyImage
            objectFit="cover"
            objectPosition="top"
            loading={index === 0 ? "eager" : "lazy"}
            key={index}
            className="productImage"
            alt={
              image?.altText
                ? image.altText
                : `Product Image of ${title} #${index + 1}`
            }
            image={image?.gatsbyImageData}
          />
        ))}
      </div>
    </ProductImageWrapper>
  )
}

export default ProductImages
